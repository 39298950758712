import React, { useEffect, useState } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom'
import { fetchDerpDetails, fetchDerps } from '../services/DerpService';
import { DetailedDerp, RankedDerp } from '../types/Derp'
import { deriveRanks, sortDerps } from '../utils/pagination'
import { numberToRankString } from '../utils/numnberToRankString'
import styled from 'styled-components'
import { shortenAddress } from '../utils/shortenAddress'

const DetailContainer = styled.div`
  text-align: center;
`;

const DetailWrap = styled.div`
  width: auto;
  max-width: 470px;
  text-align: left;
  margin: 10px auto 40px
`

const DetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  color: #77ff68;
`;

const DetailLink = styled.a`
  display: inline-block;
  margin-bottom: 20px;
  font-size: 15px;
  text-decoration: underline;
`;

const StyledHeading = styled.h1`
  color: #77ff68;
  a {
    text-decoration: none;
    color: #77ff68;
  }
  a:hover {
    text-decoration: none;
  }
`;

const DetailHeading = styled.h2`
  font-size: 24px;
  color: #00FFFF;
`;

const LevelUp = styled.span`
  color: #77ff68;
`;

const NotLevelUp = styled.span`
  color: #4a4a4a;
`;

const DetailInfo = styled.div`
  margin: 10px 0;
  
  :first-child {
    font-weight: bold;
  }
`;

const HistoryTable = styled.table`
  margin: 20px auto;
  border-collapse: collapse;
  & th, & td {
    border: 1px solid #FF00FF;
    padding: 5px 10px;
  }
  & th {
    background-color: #25003E;
    color: #00FFFF;
  }
`;

const Muted = styled.div`
  color: #573a5f;
  margin: 20px 0;
`;
const SmallAddress = styled.span`
  font-size: 15px;
  overflow-wrap: anywhere;
`

const LFG = styled.a`
  margin-top: -10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: black;
  display: inline-block;
  color: white;
  white-space: nowrap;
  &:hover {
    text-decoration: none !important;
  }
`

const DetailPage = () => {
  const { inscriptionId } = useParams<{ inscriptionId: string }>();
  const location = useLocation();
  const state = location.state as { derp: RankedDerp };
  const [detail, setDetail] = useState<DetailedDerp | null>(null);
  const [movementsLoading, setMovementsLoading] = useState(true);
  const [loadedRank, setLoadedRank] = useState<null | number>(null)
  const [rankLoading, setRankLoading] = useState(true);

  useEffect(() => {
    setMovementsLoading(true);
    if (inscriptionId) {
      fetchDerpDetails(inscriptionId).then(data => {
        setDetail(data);
        setMovementsLoading(false);
      });
      if (!state?.derp?.rank) {
        setRankLoading(true)
        fetchDerps().then(data => {
          const rankedDerps = deriveRanks(sortDerps(data))
          const thisDerp = rankedDerps.find(derp => derp.InscriptionId === inscriptionId)
          setLoadedRank(thisDerp?.rank ?? null)
          setRankLoading(false)
        })
      }
    }
  }, [inscriptionId, state]);

  const lfg = <LFG href={'https://twitter.com/lfg'} target={'_blank'} rel={"noreferrer"}>
    INDEXER POWERED BY @LFG
  </LFG>

  const name = detail?.derp?.Name ?? state?.derp?.Name ?? 'DERP #…'
  const level = detail?.derp?.Level ?? state?.derp?.Level ?? '…'
  const rank = state?.derp?.rank ?? loadedRank ?? '…'
  const owner = detail?.derp?.Owner ?? state?.derp?.Owner ?? '…'
  const rankString = typeof rank === 'number' ? numberToRankString(rank) : rank

  return (
    <DetailContainer>
      <StyledHeading>
        <Link to={'/'}>DERP RANKING</Link>
      </StyledHeading>
      {lfg}
      <DetailWrap>
        <DetailHeader>
          <DetailHeading>{name}</DetailHeading>
          <DetailInfo><div>LVL</div><div>{level}</div></DetailInfo>
          <DetailInfo><div>RANK</div><div>{rankString}</div></DetailInfo>
        </DetailHeader>
        <DetailLink href={"https://magiceden.io/ordinals/item-details/" + inscriptionId} target="_blank" rel="noopener noreferrer">View on Magic Eden</DetailLink>
        <p>
          <DetailInfo>Owned by<br /><br /><SmallAddress>{owner}</SmallAddress></DetailInfo>
        </p>
      </DetailWrap>
      <h2>Trade History</h2>
      {(detail?.movements.length === 0 ?
        <Muted>No trade history to show.</Muted>
        : !movementsLoading ?
        <HistoryTable>
          <thead>
          <tr>
            <th>BLOCK</th>
            <th>FROM</th>
            <th>TO</th>
            <th>TX</th>
            <th>LVL</th>
          </tr>
          </thead>
          <tbody>
          {detail?.movements.sort((a, b) => b.Height - a.Height).map((movement, index) => (
            <tr key={index}>
              <td>{movement.Height}</td>
              <td>{shortenAddress(movement.FromAddr)}</td>
              <td>{shortenAddress(movement.ToAddr)}</td>
              <td><a href={`https://mempool.space/tx/${movement.Txid}`} target="_blank"
                     rel="noopener noreferrer">View</a></td>
              <td>{movement.PossibleSale ? <LevelUp>+1</LevelUp> : <NotLevelUp>-</NotLevelUp>}</td>
            </tr>
          ))}
          </tbody>
        </HistoryTable>
        : <Muted>LOADING…</Muted>)}
      <Link to="/">&laquo;&nbsp;Back</Link>
      <br />
      <br />
    </DetailContainer>
  );
};

export default DetailPage;
