import React from 'react';
import GlobalStyle from './GlobalStyle';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import DetailPage from './components/DetailPage';

const App = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:page" element={<HomePage />} />
          <Route path="/derp/:inscriptionId" element={<DetailPage />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
