import { Derp, RankedDerp } from '../types/Derp'

export const sortDerps = <T extends Derp | RankedDerp>(derps: T[]): T[] => {
  return derps.sort((a, b) => b.Level - a.Level || a.Number - b.Number);
};

export const deriveRanks = (derps: Derp[]): (RankedDerp)[] => {
  let rank = 1;
  return derps.map((derp, index, array) => {
    if (index > 0 && derp.Level < array[index - 1].Level) {
      rank = index + 1;
    }
    return { ...derp, rank };
  });
};

export const paginate = (derps: RankedDerp[], page: number, itemsPerPage: number): RankedDerp[] => {
  const startIndex = (page - 1) * itemsPerPage;
  return derps.slice(startIndex, startIndex + itemsPerPage);
};
