import axios from 'axios';
import { Derp, DetailedDerp } from '../types/Derp'

const BASE_URL = 'https://api.derp.one';

export const fetchDerps = async () => {
  const response = await axios.get<Derp[]>(`${BASE_URL}/derps`);
  return response.data;
};

export const fetchDerpDetails = async (inscriptionId: string) => {
  const response = await axios.get<DetailedDerp>(`${BASE_URL}/derp/${inscriptionId}`);
  return response.data;
};
