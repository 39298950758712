import React, { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { fetchDerps } from '../services/DerpService';
import { Derp, RankedDerp } from '../types/Derp'
import { paginate, sortDerps, deriveRanks } from '../utils/pagination';
import { numberToRankString } from '../utils/numnberToRankString'
import styled from 'styled-components';
import { shortenAddress } from '../utils/shortenAddress'

const PageContainer = styled.div`
  text-align: center;
`;

const StyledHeading = styled.h1`
  color: #77ff68;
  a {
    text-decoration: none;
    color: #77ff68;
  }
  a:hover {
    text-decoration: none;
  }
`;

const NavLinks = styled.div`
  font-size: 18px;
  display: flex;
  gap: 30px;
  justify-content: space-between;
  max-width: 375px;
  margin: 14px auto 32px;
`

const StyledNav = styled.nav`
  display: inline-block;
  margin: 0 0 16px;
  & a {
    margin: 0 10px;
    padding: 5px 10px;
    background-color: #5800FF;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
  }
`;

const StyledTable = styled.table`
  margin: auto;
  border-collapse: collapse;
  & th, & td {
    border: 1px solid #FF00FF;
    padding: 5px 10px;
  }
  & th {
    background-color: #25003E;
    color: #00FFFF;
  }
`;

const Muted = styled.div`
  color: #573a5f;
  margin: 40px 0;
`;

const LFG = styled.a`
  margin-top: -10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: black;
  display: inline-block;
  color: white;
  white-space: nowrap;
  &:hover {
    text-decoration: none !important;
  }
`

const SearchInput = styled.input`
  margin: -4px 0 4px;
  padding: 10px 14px;
  font-size: 15px;
  background-color: #0f0f23;
  border: 2px solid #89CFF0;
  color: #ff89fa;
  font-family: 'Press Start 2P', cursive;
  outline: none;

  &::placeholder {
    color: #576574;
    opacity: 0.7;
  }

  transition: border-color 0.25s ease-in-out;
`;

const SearchToggle = styled.div`
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  font-size: 24px;
`;

const HomePage = () => {
  const { page } = useParams<{ page?: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const currentPage = parseInt(page || '1', 10);
  const itemsPerPage = 100;
  const [totalPages, setTotalPages] = useState(0);
  const [rankedData, setRankedData] = useState<RankedDerp[]>([]);
  const [filter, setFilter] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
    if (isFilterVisible) {
      setFilter('');
    }
  };

  const currentPageData = paginate(rankedData, currentPage, itemsPerPage);

  const matches = rankedData.filter((derp) => {
    if (!filter.trim()) {
      return true;
    }

    const filterLower = filter.toLowerCase();
    const derpNumber = derp.Name.split('#')[1].trim();
    const includeOwnerInSearch = filter.length >= 4 && derp.Owner.toLowerCase().includes(filterLower);
    const isExactMatch = derp.Level.toString() === filter || derp.rank.toString() === filter || derpNumber === filter;
    return isExactMatch || includeOwnerInSearch;
  });

  const sortMatches = (a: RankedDerp, b: RankedDerp) => {
    const aNumber = a.Name.split('#')[1].trim();
    const bNumber = b.Name.split('#')[1].trim();
    const exactDerpNumberMatchA = aNumber === filter;
    const exactDerpNumberMatchB = bNumber === filter;
    const exactMatchA = a.Level.toString() === filter || a.rank.toString() === filter || exactDerpNumberMatchA;
    const exactMatchB = b.Level.toString() === filter || b.rank.toString() === filter || exactDerpNumberMatchB;

    if (exactDerpNumberMatchA && !exactDerpNumberMatchB) {
      return -1;
    } else if (!exactDerpNumberMatchA && exactDerpNumberMatchB) {
      return 1;
    }

    if (exactMatchA && !exactMatchB) {
      return -1;
    } else if (!exactMatchA && exactMatchB) {
      return 1;
    }

    return 0;
  };

  const filteredData = matches.sort(sortMatches);

  const displayData = isFilterVisible && filter !== '' ? filteredData : currentPageData

  useEffect(() => {
    setLoading(true);
    fetchDerps().then(data => {
      const sortedData = sortDerps(data);
      const ranked = deriveRanks(sortedData);
      setRankedData(ranked);
      setTotalPages(Math.ceil(sortedData.length / itemsPerPage));
      setLoading(false);
    }).catch(error => {
      console.error('Failed to fetch derps:', error);
      setLoading(false);
    });
  }, []);

  const lfg = <LFG href={'https://twitter.com/lfg'} target={'_blank'} rel={"noreferrer"}>
    INDEXER POWERED BY @LFG
  </LFG>

  const navlinks = <NavLinks>
    <a href={'https://www.ord.io/58160255'} target={'_blank'} rel={"noreferrer"}>Rules</a>
    <a href={'https://twitter.com/btcmachine98'} target={'_blank'} rel={"noreferrer"}>X</a>
    <a href={'https://magiceden.io/ordinals/marketplace/derp'} target={'_blank'} rel={"noreferrer"}>Trade on ME</a>
  </NavLinks>


  const searchInput = (
    <>
      <SearchInput
        type="text"
        placeholder="#, RANK, LVL, OWNER"
        value={filter}
        onChange={handleInputChange}
      />
      <SearchToggle onClick={toggleFilterVisibility}> ☓ </SearchToggle>
    </>
  );

  const searchToggle = (
    <SearchToggle onClick={toggleFilterVisibility}>🔍</SearchToggle>
);

  const forwardPageText = currentPage === 1 ? <>Next &raquo;</> : <>&raquo;</>
  const paginator =
    <StyledNav aria-label="Page navigation">
      {currentPage > 1 && (
        <Link to={`/${currentPage - 1}`}>&laquo;</Link>
      )}
      <span>{currentPage} of {totalPages}</span>
      {currentPage < totalPages && (
        <Link to={`/${currentPage + 1}`}>{forwardPageText}</Link>
      )}
    </StyledNav>

  if (loading) {
    return <PageContainer>
      <StyledHeading>DERP RANKING</StyledHeading>
      {lfg}
      {navlinks}
      <Muted>LOADING…</Muted>
    </PageContainer>
  }

  if (currentPage < 1 || currentPage > totalPages) {
    navigate('/1', { replace: true });
    return null;
  }

  return (
    <PageContainer>
      <StyledHeading>
        <Link to={'/'}>DERP RANKING</Link>
      </StyledHeading>
      {lfg}
      {navlinks}
      {isFilterVisible ? searchInput : paginator}
      {isFilterVisible ? null : searchToggle}
      <StyledTable>
        <thead>
        <tr>
          <th>RANK</th>
          <th>DERP</th>
          <th>LVL</th>
          <th>OWNER</th>
        </tr>
        </thead>
        <tbody>
        {displayData.map((derp) => (
          <tr key={derp.InscriptionId}>
            <td>{numberToRankString(derp.rank)}</td>
            <td style={{textAlign: 'left'}}><Link to={`/derp/${derp.InscriptionId}`} state={{ derp: derp }}>
              {derp.Name.split(' ')[1]}
            </Link></td>
            <td>{derp.Level}</td>
            <td>{shortenAddress(derp.Owner)}</td>
          </tr>
        ))}
        </tbody>
      </StyledTable>
      {!isFilterVisible && paginator}
    </PageContainer>
  );
};

export default HomePage;
