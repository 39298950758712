import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #0f0f23;
    background-image: url('/images/derp-fade.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    image-rendering: pixelated;
    color: #ff89fa;
    font-family: 'Press Start 2P', cursive;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-smooth: never;
    -webkit-font-smoothing : none;
  }

  a {
    color: #89CFF0;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default GlobalStyle;
